import React from 'react';

import { API_ROOT, VISITOR_ID_KEY } from '@constants';

function useViewCheckout() {
  React.useEffect(() => {
    const visitorId = window.localStorage.getItem(VISITOR_ID_KEY);

    fetch(`${API_ROOT}/conversions/mark`, {
      method: 'POST',
      body: JSON.stringify({
        visitorId,
        courseSlug: 'css-for-js',
        event: 'view-checkout',
      }),
    });
  }, []);
}

export default useViewCheckout;
