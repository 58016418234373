export function formatPrice(price, includeSuffix = true) {
  const country = 'US';
  const currency = 'USD';

  const priceString = new Intl.NumberFormat(`en-${country}`, {
    style: 'currency',
    currency,
  }).format(price);

  return includeSuffix ? priceString + ' USD' : priceString;
}
