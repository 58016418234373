import React from 'react';
import styled from 'styled-components';

import useViewCheckout from '@hooks/use-view-checkout.hook';
import useIsOnSale from '@hooks/use-is-on-sale.hook';
import { getFormattedTimeRemaining } from '@helpers/time.helpers';

import SEO from '@components/SEO/SEO';
import ClientOnly from '@components/ClientOnly';
import Spacer from '@components/Spacer';
import Footer from '@components/Footer';
import {
  TopCurve,
  BottomCurve,
} from '@components/TestimonialWrapper/TestimonialWrapper.curves';

import TeamCheckoutProcess from '@components/Checkout/TeamCheckoutProcess';
import TeamFaq from '@components/Checkout/TeamFaq';

const TeamsCheckout = () => {
  const { isOnSale, saleEnds, timeRemaining } = useIsOnSale();

  useViewCheckout();

  return (
    <OuterWrapper>
      <Wrapper>
        <Header>
          <h1>Team Licenses</h1>
          <p>
            Purchase multiple licenses to level up the entire team!
            Each team member will receive full access to the
            “Ultimate” package of{' '}
            <em>CSS for JavaScript Developers</em>.
          </p>
          {isOnSale && (
            <SaleBox>
              <h2>Black Friday sale!</h2>
              <p>
                Get <strong>50% off</strong> all team license
                purchases, regardless of quantity.{' '}
                <em>
                  Sale ends {getFormattedTimeRemaining(timeRemaining)}
                </em>
              </p>
            </SaleBox>
          )}
        </Header>

        <ClientOnly>
          <TeamCheckoutProcess />
        </ClientOnly>
      </Wrapper>
      <Spacer $size={64} />
      <TopCurveWrapper>
        <TopCurve />
      </TopCurveWrapper>
      <Spacer $size={32} />
      <TeamFaq />
      <Spacer $size={128} />
      <BottomCurveWrapper>
        <BottomCurve />
      </BottomCurveWrapper>
      <Footer />
      <SEO title="Team Licenses » CSS for JavaScript Developers" />
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  --footer-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const Wrapper = styled.main`
  margin: 0 auto;
  max-width: 650px;
  isolation: isolate;
`;

const Header = styled.header`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 128px 32px 0;
  margin-bottom: 32px;

  h1 {
    margin-bottom: 16px;
    font-size: 3rem;
    font-weight: var(--font-weight-bold);
    background-color: #ffb23e;
    background-image: linear-gradient(
      200.67deg,
      #ffffff 25%,
      hsl(54deg 100% 70%) 35%,
      hsl(36deg 100% 55%) 55.54%,
      hsl(333deg 100% 50%) 90%
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  p {
    font-size: 1.25rem;
  }

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding: 64px 24px 0;
  }
`;

const SaleBox = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px 24px 24px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 32px;
  text-align: center;
  background: hsl(223deg 20% 12.5% / 0.25);
  /* prettier-ignore */
  box-shadow:
    0px 1px 4px hsl(223deg 50% 75% / 0.1),
    0px 1px 8px hsl(223deg 50% 75% / 0.075),
    0px 2px 16px hsl(223deg 50% 75% / 0.05),
    0px 4px 32px hsl(223deg 50% 75% / 0.08),
    0px 8px 64px hsl(223deg 50% 75% / 0.09),
    0px 16px 128px hsl(223deg 50% 75% / 0.1);
  h2 {
    color: hsl(50deg 100% 80%);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0px;
  }
`;

const CurveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 160px;
  /* Truncate curve if it leaves the region */
  overflow: hidden;
`;
const TopCurveWrapper = styled(CurveWrapper)`
  justify-content: flex-end;
  background: linear-gradient(
    to top,
    hsl(0deg 0% 100% / 0.15),
    hsl(0deg 0% 100% / 0)
  );
  transform: translateY(1px);
`;
const BottomCurveWrapper = styled(CurveWrapper)`
  background: linear-gradient(
    to bottom,
    hsl(0deg 0% 100% / 0.15),
    hsl(0deg 0% 100% / 0)
  );
  transform: translateY(-1px);
`;

export default TeamsCheckout;
