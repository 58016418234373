import React from 'react';
import styled, { keyframes } from 'styled-components';
import queryString from 'query-string';

import {
  API_ROOT,
  PADDLE_VENDOR_ID,
  VISITOR_ID_KEY,
} from '@constants';
import { debounce } from '@utils';
import { formatPrice } from '@helpers/price.helpers';

import Spinner from '@components/Spinner';
import BuyButton from '@components/BuyButton';
import Paper from '@components/Paper';

const DEBUG_PADDLE = false;

function TeamCheckoutProcess() {
  const [status, setStatus] = React.useState('idle');
  const [numOfLicenses, setNumOfLicenses] = React.useState(5);
  const [subtotal, setSubtotal] = React.useState(null);
  const [discountedSubtotal, setDiscountedSubtotal] = React.useState(
    null
  );
  const [checkoutUrl, setCheckoutUrl] = React.useState(null);
  const [merchant, setMerchant] = React.useState(null);

  let activeFetchIndex = React.useRef(0);

  const fetchPrices = React.useCallback((numOfLicenses) => {
    const fetchIndex = activeFetchIndex.current + 1;
    activeFetchIndex.current = fetchIndex;

    const queryParams = queryString.stringify({
      // Forward any query parameters:
      ...queryString.parse(window.location.search),
      numOfLicenses,
      courseSlug: 'css-for-js',
    });

    fetch(
      `${API_ROOT}/pricing/get-price-for-multiple-licenses?${queryParams}`
    )
      .then((res) => res.json())
      .then(
        ({
          subtotal,
          discountedSubtotal,
          merchant,
          merchantMetadata,
        }) => {
          if (fetchIndex !== activeFetchIndex.current) {
            return;
          }

          setSubtotal(subtotal);
          setDiscountedSubtotal(discountedSubtotal);
          setMerchant(merchant);
          setCheckoutUrl(merchantMetadata?.checkoutUrl);
        }
      );
  }, []);

  // We need to wait for the async script to load, and we don't know
  // when that happens. Set up an interval loop that runs until the
  // script has finished loading.
  React.useEffect(() => {
    if (merchant !== 'paddle') {
      return;
    }

    const intervalId = window.setInterval(() => {
      const hasScriptLoaded = !!window.Paddle;

      if (hasScriptLoaded) {
        window.Paddle.Setup({
          vendor: PADDLE_VENDOR_ID,
          debug: DEBUG_PADDLE,
        });

        window.clearInterval(intervalId);
      }
    }, 100);
  }, [merchant]);

  React.useEffect(() => {
    setSubtotal(null);
    setDiscountedSubtotal(null);
    setCheckoutUrl(null);

    fetchPrices(numOfLicenses);
  }, [numOfLicenses]);

  function handleCancel() {
    setStatus('idle');
  }

  async function handleSuccessfulPurchase(paddleData) {
    setStatus('success');
  }

  function handleBuyClick(ev) {
    if (numOfLicenses < 1) {
      window.alert('Please include at least 1 license to continue.');
      return;
    }

    if (merchant !== 'paddle') {
      return;
    }

    setStatus('checking-out');

    const visitorId = window.localStorage.getItem(VISITOR_ID_KEY);

    window.Paddle.Checkout.open({
      title: `CSS for JavaScript Developers (${numOfLicenses} license${
        numOfLicenses > 1 ? 's' : ''
      })`,
      message: 'Level up the entire team!',
      override: checkoutUrl,
      marketingConsent: '0',
      allowQuantity: false,
      closeCallback: handleCancel,
      successCallback: handleSuccessfulPurchase,
      passthrough: JSON.stringify({
        visitorId,
        numOfLicenses,
        purchaseType: 'team-external',
      }),
    });
  }

  if (status === 'success') {
    return (
      <SuccessWrapper>
        <h2>Your purchase was successful!</h2>

        <p>
          You'll receive an email in the next few minutes with
          information about how to onboard your team members.
        </p>
        <p>
          If you run into any issues, please reach out by email at{' '}
          <a href="mailto:support@joshwcomeau.com">
            support@joshwcomeau.com
          </a>
          .
        </p>
      </SuccessWrapper>
    );
  }

  return (
    <>
      <Breakdown>
        <RowWrapper>
          <Row>
            <Label>Number of Licenses</Label>
            <Value>
              <NumberInput
                value={numOfLicenses}
                onChange={(ev) =>
                  setNumOfLicenses(
                    Math.min(200, Number(ev.target.value))
                  )
                }
                type="number"
                min={1}
                max={200}
              />
            </Value>
          </Row>
        </RowWrapper>
        <RowWrapper>
          <Row>
            <Label>Subtotal</Label>
            <Value>
              <Subtotal
                subtotal={subtotal}
                discountedSubtotal={discountedSubtotal}
              />
            </Value>
          </Row>
        </RowWrapper>
      </Breakdown>

      <TriggerButton
        href={merchant !== 'paddle' ? checkoutUrl : undefined}
        target="_blank"
        onClick={handleBuyClick}
        disabled={!checkoutUrl || status !== 'idle'}
      >
        {status === 'idle' ? 'Purchase licenses' : <Spinner />}
      </TriggerButton>
    </>
  );
}

function Subtotal({ subtotal, discountedSubtotal }) {
  if (typeof subtotal !== 'number') {
    return <Spinner size={16} />;
  }

  if (
    typeof discountedSubtotal === 'number' &&
    subtotal !== discountedSubtotal
  ) {
    return (
      <>
        <Strikethrough>{formatPrice(subtotal, false)}</Strikethrough>
        <BetterPrice>{formatPrice(discountedSubtotal)}</BetterPrice>
      </>
    );
  }

  return formatPrice(subtotal);
}

const TriggerButton = styled(BuyButton)`
  width: 250px;
  margin: 64px auto;
`;

const successEnter = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5) rotate(-10deg);
  }
`;

const SuccessWrapper = styled(Paper)`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  animation: ${successEnter} 1000ms 200ms both
    cubic-bezier(0.17, 0.67, 0.13, 1.01);

  /* Border decoration */
  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 2px dashed var(--color-gray-300);
    pointer-events: none;
    border-radius: 2px;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: var(--font-weight-medium);
    margin-bottom: 1.5em;
  }

  a {
    color: hsl(230deg, 92%, 50%);
    font-weight: var(--font-weight-medium);
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Strikethrough = styled.del`
  position: relative;
  display: inline-block;
  margin-right: 8px;
  color: var(--color-gray-700);
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: hsl(345deg 100% 50%);
    border-radius: 2px;
    transform: rotate(-5deg);
    transform-origin: center center;
    pointer-events: none;
  }
`;

const BetterPrice = styled.span`
  font-weight: 400;
  background-color: #ffb23e;
  background-image: linear-gradient(
    200deg,
    #ffffff 3.43%,
    hsl(54deg 100% 70%),
    hsl(36deg 100% 55%)
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const Breakdown = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const RowWrapper = styled.div`
  padding: 24px 0;
  border-bottom: 2px dotted hsl(0deg 0% 100% / 0.4);
  margin-bottom: 1px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media (max-width: 800px) {
    padding: 24px 32px;
  }

  @media (max-width: 550px) {
    padding: 24px 8px;
  }
`;

const Row = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: hsl(265deg 30% 50% / 0.25); */
`;

const NumberInput = styled.input`
  color: white;
  background: transparent;
  border: 2px solid;
  padding: 8px;
  width: 8rem;
  border-radius: 4px;
  outline-offset: 4px;
  font-size: 1.25rem;
`;

const Label = styled.dt``;
const Value = styled.dd``;

export default TeamCheckoutProcess;
