import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Em from '@components/Em';

import Question from '@components/Faq/Question';

const TeamFaq = () => {
  return (
    <Wrapper as="section" maxWidth={900}>
      <Header>
        <h2>Common Questions</h2>
      </Header>

      <Grid>
        <Question
          question="Will I receive a receipt?"
          answer={
            <>
              <p>
                Definitely! You'll receive a copy of your receipt
                immediately upon purchase. You'll be able to edit it
                to add any specific info you need (name, address, VAT
                numbers…).
              </p>
            </>
          }
        />
        <Question
          question="What happens after I purchase?"
          answer={
            <>
              <p>
                When you purchase team licenses, an administrator
                account will be created for you. A few minutes after
                purchase, you'll be sent a login link by email.
              </p>
              <p>
                You'll log onto my custom course platform. As a team
                administrator, you'll be able to send invitations to
                your teammates. You'll enter the names and emails of
                every license-holder, and they'll receive a welcome
                email with instructions for onboarding.
              </p>
            </>
          }
        />
        <Question
          question="Can I purchase additional licenses later?"
          answer={
            <>
              <p>
                Yes! You can purchase additional licenses through the
                Team Dashboard.
              </p>
            </>
          }
        />
        <Question
          question="Can I reassign licenses?"
          answer={
            <>
              <p>
                In general, no. But in certain cases, we may be able
                to make exceptions. For example, if an employee leaves
                your organization shortly after receiving a license.
                But in general, you'll need to purchase new licenses
                for each additional teammate.
              </p>
            </>
          }
        />
        <Question
          question="Is there a bulk discount?"
          answer="Yes! If you buy at least 4 licenses, you'll receive a discount. Discounts depend on quantity and range from 10% to 20% off the current price."
        />
        <Question
          question="Do team licenses support regional pricing?"
          answer="Sorry — right now, team licenses are only available at the US price point. This is something I might change in the future."
        />
        <Question
          question="Can I purchase team licenses for the Basic or Pro tier?"
          answer={
            <>
              <p>
                Afraid not. Only the full course (the “Ultimate”
                package) can be purchased in bulk.
              </p>
            </>
          }
        />
        <Question
          question="Are progress reports available, so I can see how far along each team member is in the course?"
          answer={
            <>
              <p>
                Unfortunately not. I can absolutely understand that
                this information would be valuable, but I'm not
                totally comfortable exposing really granular
                information in the team dashboard.
              </p>
              <p>
                If you're worried about team members not finishing the
                course, I do make a "Certificate of Completion"
                available. You can ask your team members to submit
                their certificates to verify that they've completed
                the course.
              </p>
              <p>
                In the future, I do plan on adding additional data to
                the team dashboard, to help you understand how the
                team is progressing, but that data will likely be
                aggregated.
              </p>
            </>
          }
        />
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(MaxWidthWrapper)`
  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 72px;
    text-align: center;

    @media (max-width: 500px) {
      padding: 0 16px;
    }
  }

  /*
    HACK: Reaching into children.
    This is a bad practice indeed, I'm just being lazy.
  */
  a {
    color: hsl(265deg 100% 80%);
  }

  ul {
    list-style-type: initial;
    padding-left: 20px;
  }

  li {
    margin-bottom: 12px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 32px;
  padding: 0 32px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
`;

export default TeamFaq;
